:root {
  --online: #44b700;
  --offline: #b74400;
}

body {
  font-family: 'Roboto';
  font-size: 16px;
}

.App {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f4f6fc;
  align-items: center;
}

.header {
  width: 100% !important;
  padding: 0 1rem !important;
  background-color: #233dff !important;
  color: #f4f6fc !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.header-links {
  color: #f4f6fc !important;
}

.error {
  margin: 0.5rem;
  color: red;
  text-align: center;
}
